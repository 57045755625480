import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { KEYWORDS } from '/src/data/';
import { FAQSection } from 'src/sections';
import {
  Page,
  Section,
  SmolBlock,
  SplitHero,
  SlasherPrefooter,
  Grid,
  Income,
} from '/src/components';
import { $t, locale } from 'src/utils';

const QUERY = graphql`
  query SlasherFaq2 {
    en: allGraphCmsFaq(filter: { contexts: { in: health_slasher }, locale: { eq: en } }) {
      nodes {
        title
        explanation
      }
    }
    es: allGraphCmsFaq(filter: { contexts: { in: health_slasher }, locale: { eq: es } }) {
      nodes {
        title
        explanation
      }
    }
  }
`;

const Slasher = () => {
  const data = useStaticQuery(QUERY);
  const _faqs = data?.[locale]?.nodes;

  return (
    <Page
      prefooter={<></>}
      image="/img/og/slasher.png"
      hideLinks
      hideButtons
      metadata={{
        title: $t({
          id: 'benefits/health/slasher.meta-title',
          defaultMessage: 'Keep Your Coverage, Cut The Bill.',
        }),
        description: $t({
          id: 'benefits/health/slasher.meta-description',
          defaultMessage:
            'Find tax credits you may have missed to lower your health insurance premiums.',
        }),
        keywords: KEYWORDS['Health'],
        vertical: 'HEALTH',
        faqs: _faqs,
        canonical: '/benefits/health/slasher',
        steps: {
          name: 'Slash your premiums',
          minutes: 2,
          steps: [
            { name: 'Connect your health plan' },
            { name: 'Find your tax credits' },
            { name: 'Save money in 2 minutes' },
          ],
        },
      }}
    >
      <SplitHero
        eb={$t({
          id: 'benefits/health/slasher.pretitle',
          defaultMessage: 'Premium Slasher',
        })}
        title={$t({
          id: 'benefits/health/slasher.title',
          defaultMessage: 'Keep Your Coverage, Cut The Bill.',
        })}
        subtitle={$t({
          id: 'benefits/health/slasher.subtitle',
          defaultMessage:
            'Catch’s Premium Slasher lowers your health insurance premiums in two minutes or less.',
        })}
        accessory={<img src="/slasher.png" height={550} alt="Man smiling" />}
      >
        <Income buttonText="Find Savings" slasher />{' '}
        <b1>
          <br />
          <span
            style={{
              color: 'var(--c-success)',
              fontFamily: 'var(--ff-creatour)',
              paddingLeft: 0,
            }}
          >
            $524/mo
          </span>{' '}
          in average savings
        </b1>
      </SplitHero>

      <Section raw>
        <Grid num={3}>
          <SmolBlock
            color="var(--g-red-light)"
            icon="/iconic/linked.svg"
            alt="linked icon"
            title={$t({ id: 'section.slasher-item-1.title', defaultMessage: 'Connect ' })}
            text={$t({
              id: 'section.slasher-item-1.description',
              defaultMessage: 'your health plan',
            })}
          />
          <SmolBlock
            color="var(--g-red-light)"
            icon="/iconic/tag.svg"
            alt="tag icon"
            title={$t({ id: 'section.slasher-item-2.title', defaultMessage: 'Find ' })}
            text={$t({
              id: 'section.slasher-item-2.description',
              defaultMessage: 'your tax credits',
            })}
          />
          <SmolBlock
            color="var(--g-red-light)"
            icon="/iconic/dollar-2.svg"
            alt="dolllar icon"
            title={$t({ id: 'section.slasher-item-3.title', defaultMessage: 'Save money ' })}
            text={$t({ id: 'section.slasher-item-3.description', defaultMessage: 'in 2 minutes' })}
          />
        </Grid>
      </Section>
      <Section small />
      <SlasherPrefooter />

      <FAQSection color={'var(--c-red-0)'} faqs={_faqs} theme="light" />
    </Page>
  );
};

export default Slasher;
